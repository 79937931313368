@import 'styles/vars.scss';
@import 'styles/style.scss';

#id {
  overflow: hidden;
}

.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  //height:100vh;
  height: 100%;
  //color:white;

  div[data-amplify-authenticator] {
    margin-top: 100px;
  }

  .amplify-tabs {
    &-item {
      &:hover {
        cursor: default;
      }
      &[data-state='inactive'] {
        display: none;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: row;
    width: 100%;
    /*     padding-top: 90px;
 */
    height: 100%;
  }

  .content {
    width: 100%;
    margin: 90px 10px 10px 10px;
    position: relative;
    height: calc(100vh - 90px);
    overflow-y: scroll;
    .breadcrumb {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  /* .header {
    background-color: $color-surface;
    width: 100%;
    color: white;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    z-index: 500;

    .burger {
      margin-left: 20px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &--clicked {
        transform: rotate(90deg);
      }
    }

    .brand {
      order: 1;
      flex: 1;
      font-weight: bold;
      font-size: 0.8rem;
      padding: 20px;
      border-right: 1px solid $color-primary-hover;
      height: 70px;
      display: flex;
      align-items: center;
      em {
        color: lightgray;
        font-style: normal;
        font-weight: initial;
      }
      width: 300px;
      justify-content: center;
    }

     .signout {
      order: 2;
      padding: 20px;
      color: white;
      text-decoration: none;
      width: 100px;
      span {
        display: inline-block;
        margin: 0 auto;
        transition: all 0.2s ease-in-out;
        &:hover {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    } 
  } */
}

.mobile-hide {
  display: none;
}

@media (min-width: 750px) {
  .App {
    text-align: left;
    display: flex;
    //overflow: hidden;

    .main {
      display: flex;
      flex-direction: row;
      width: 100%;
      /*       padding-top: 90px;
 */
    }

    .content {
      padding: 0px 20px 20px 20px;
      margin: 90px 0 0 0px;
      overflow-y: scroll;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    }

    .list {
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
    }

    /* .header {
      width: 100%;
      color: white;
      position: fixed;
      z-index: 500;

      .burger {
        display: none;
      }

      .brand {
        flex: none;
      }
      .signout {
        order: 3;
      }
    } */
  }

  .mobile-hide {
    display: flex;
  }
}

:root {
  --amplify-primary-color: $color-primary;
  margin: 0;
  padding: 0;

  amplify-loading-spinner {
    margin-top: 100px;
    display: block;
  }
}

.loader-container {
  max-width: 600px;
  .loading-spinner {
    /*  control spinner size with setting font-size  */
    font-size: 3rem;
    border: 0.8rem solid $color-primary;
    border-top-color: $color-primary-hover;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: loading-spinner 2s linear infinite;
    margin: 50px auto 0 auto;
    box-sizing: border-box;
  }

  .little {
    font-size: 1rem;
    border: 0.3rem solid $color-primary;
    border-top-color: $color-primary-hover;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0px auto 0 auto;
  }
}

.loader {
  margin: 1rem auto 0 auto;
  width: 32px;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.button-primary,
span.button-primary {
  text-transform: capitalize;
  color: white;
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary;
  }
  &:disabled {
    background-color: #e0e0e0;
    color: #acacac;
  }
}

button.button-secondary {
  text-transform: capitalize;
  background-color: $color-primary-hover;
  color: white;
  &:hover {
    background-color: $color-primary-hover;
  }
}

button.button-facultative {
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: gray;
  &:hover {
    background-color: #e9ecef;
  }
}

.MuiIconButton-label svg {
  color: $color-primary;
}

.title {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  //padding-left:10px;
}

.action-icon {
  position: absolute;
  top: 0px;
  left: -44px;
}
