@use 'sass:map';

@import '../../styles/mixin';
@import '../../styles/style';

.search-form {
  width: 900px;
  margin-bottom: 1rem;
}

.filters-panel {
  margin-bottom: 10px;
}

.users-infos {
  width: 100%;
  display: flex;
  height: auto;
  .users-list-container {
    height: calc(100vh - 320px);
    background-color: white;
    width: 100%;
    .users-list {
      height: 100%;
      flex: 1;
    }
  }
}

.subtitle {
  margin-left: 10px;
  color: darkgrey;
  span {
    margin: 5px 5px 0 0;
    display: inline-block;
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
}

.reset-filters {
  text-decoration: underline;
  font-size: 0.6rem;
  color: $color-primary;
  cursor: pointer;

  &:hover {
    color: lightgrey;
  }
}

.cell-link {
  width: 290px;
  height: 100%;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: white;
  }
}

.user-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
/* 
@media (min-width:650px) {
.user-modal {
    //position:absolute;
    position:absolute;
    margin: auto;
     width: 650px;
    height:400px; 
    width: 100%;
    height: 100%;
    top: 0;
     right: 0;
    bottom: 0; 
    left: 0;
}
}
*/

@media (min-width: 750px) {
  .filters-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .user-filter {
      margin-right: 5px;
      margin-bottom: 5px;
      &-email {
        width: 250px;
      }
      &-status {
        width: 200px;
      }
      &-verified {
        width: 140px;
      }
    }

    .filter-actions {
      display: flex;
      align-items: center;
    }
  }
}

@media (min-width: 1420px) {
  .user-modal {
    width: 650px;
    position: static;
    margin: 0 0 20px 20px;
    flex-shrink: 0;
  }
}

.user-card {
  margin-bottom: 10px;
  color: white;
  box-shadow: 0 0 3px lightgray;
  width: 600px;
  border-radius: $border-radius;
  /*cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 2px 5px gray;
    transform: translate3D(-3px, 0px, 0);
  } */

  .user-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: gray;
    .value {
      color: white;
    }
    span {
      span {
        margin: 0 5px;
      }
    }
  }
  .user-header {
    background-color: $color-primary;
    width: 100%;
    color: white;
    padding: 5px 10px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    font-size: map.get($typography-link-100, 'fontSize');
    gap: 10px;
    .user-email {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .user-content {
    background-color: $color-surface;
    width: 100%;
    /* border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius; */
    padding: 10px;
    font-size: 0.8rem;
  }
  .user-footer {
    background-color: $color-surface;
    width: 100%;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 10px;
    font-size: 0.8rem;
    .amplify-selectfield {
      width: 250px;
    }
    .amplify-select {
      color: white;
      &[disabled] {
        color: gray;
        background-color: black;
      }
    }
    button {
      background-color: white;
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      padding-top: 1rem;
    }
  }
}

@media (max-width: 375px) {
  .user-card {
    width: 355px;
  }
}

.section-list {
  list-style-type: none;
}

.section-manager {
  width: 600px;
  display: flex;
  flex-direction: column;
  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    gap: 5px;
  }
  .amplify-button--disabled {
    border-color: var(--amplify-internal-button-disabled-color);
  }
}

.user-list {
  list-style-type: none;
  align-items: center;
  display: flex;
  gap: 10px;
  .amplify-selectfield {
    width: 150px;
  }
  span {
    flex: 1;
  }
}
