$color-primary: #103757;
$color-primary: #5433b2;
$color-primary-hover: #678ead;
$color-primary-hover: #36414a;
$color-secondary: #ffffff;
$color-surface: #17262f;
$color-disabled: #36414a;

$border-color: #dadce0;
$shadow-color: lightgray;

$border-radius: 8px;
$font-primary: "Inter", "Open Sans";
