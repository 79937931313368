@import '../../styles/vars';

.header {
  background-color: #3c393e;
  width: 100%;
  color: white;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  //box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid lightgrey;
  //border-radius: 10px;
  //margin: 10px;
  z-index: 500;

  .burger {
    margin-left: 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &--clicked {
      transform: rotate(90deg);
    }
  }

  .brand {
    flex: 1;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 15px;
    //border-right: 1px solid $color-primary-hover;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .slash {
      font-weight: initial;
      .bo-title {
        margin-left: 5px;
        color: white;
        text-decoration: none;
      }
    }
    svg {
      width: 150px;
      &:first-child {
        width: 40px;
        display: none;
      }
    }

    width: 400px;
  }

  .members {
    background-color: #1a181b;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-decoration: none;
    &:hover {
      background-color: $color-surface;
    }

    a,
    a:visited {
      color: white;
    }
  }

  .signout {
    padding: 15px 30px 15px 20px;
    color: white;
    margin: 0 auto;
    transition: all 0.2s ease-in-out;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (min-width: 750px) {
  .header {
    //color: white;
    position: fixed;
    z-index: 500;

    .burger {
      display: none;
    }

    .signout {
      order: 3;
    }
  }
}
